<template>
  <a-sticky class="a-scroll-to-top__wrapper">
    <fade-transition>
      <div v-if="isButtonVisible">
        <div
          v-focusable
          class="a-scroll-to-top__sticky-wrapper"
          @click="scrollToTop"
        >
          <div class="slide__arrow-up" />
          Top
        </div>
      </div>
    </fade-transition>
  </a-sticky>
</template>

<script>
import { mapGetters } from 'vuex'

import ASticky from 'shared/ASticky'

export default {
  name: 'AScrollToTop',
  components: { ASticky },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    }),
    isButtonVisible() {
      const scrollToTopBreakpoint = 1500
      return this.scrollTop > scrollToTopBreakpoint
    }
  },
  methods: {
    scrollToTop() {
      this.$scrollTo('body', 500)
    }
  }
}
</script>

<style lang="scss">
.a-scroll-to-top__wrapper {
  right: 0;
  top: calc(100% - 100px);
  width: 35px;
  height: 35px;
  max-height: 35px;
  overflow: hidden;
  z-index: $z-index-widget-wrapper;
  cursor: pointer;

  .a-scroll-to-top__sticky-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6px 7px 8px;
    background: $c--main;
    color: white;
    font-family: $font-sans;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    pointer-events: all;

    &:hover {
      opacity: 0.7;
    }
  }

  .slide__arrow-up {
    border-width: 2px 0 0 2px;
    padding: 3px;
    border-color: white;
  }
}
</style>
