<template>
  <div
    :class="$options.consts.WIDGETS_WRAPPER_CLASS"
    @blur.capture="onWidgetsBlur"
  >
    <a-widgets
      :is-disabled="inProgress"
      :display-widget-buttons="displayWidgetButtons"
    />
    <client-only>
      <a-scroll-to-top />
    </client-only>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import * as types from '@/store/mutation-types'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import AScrollToTop from 'shared/AScrollToTop'
import { CLOSE_LAYOUT_WIDGETS_BUS_NAME } from 'shared/AWidgets/enums'
import { WIDGETS_WRAPPER_CLASS } from '@/components/_layout/ALayoutWidgets/enums'
import { hasParentWith } from '@fmpedia/helpers'

export default {
  name: 'ALayoutWidgets',
  props: {
    displayWidgetButtons: propValidator([PROP_TYPES.BOOLEAN], false, true)
  },
  components: { AWidgets: () => import('shared/AWidgets'), AScrollToTop },
  consts: {
    WIDGETS_WRAPPER_CLASS
  },
  data() {
    return {
      inProgress: true
    }
  },
  methods: {
    ...mapActions({
      requestFormsSettings: 'leads/requestFormsSettings'
    }),
    ...mapMutations({
      setStickyWidgetsAsNotPending: types.SET_STICKY_WIDGETS_AS_NOT_PENDING
    }),
    async onWidgetsBlur(event) {
      const { relatedTarget } = event

      if (this.$helper.isRecaptchaChallenge(relatedTarget)) return

      if (
        relatedTarget &&
        !hasParentWith(relatedTarget, {
          className: WIDGETS_WRAPPER_CLASS
        })
      ) {
        this.$bus.$emit(CLOSE_LAYOUT_WIDGETS_BUS_NAME)
      }
    },
    async getWidgetSettings() {
      try {
        await this.requestFormsSettings()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
        this.setStickyWidgetsAsNotPending()
      }
    }
  },
  mounted() {
    this.getWidgetSettings()
  }
}
</script>

<style lang="scss" scoped>
.widgets-wrapper {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  height: 100%;
  padding-left: 5px;
  z-index: $z-index-widget-wrapper;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
